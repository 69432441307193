import { render, staticRenderFns } from "./G27Detail1TenderStat.vue?vue&type=template&id=8e0f3b26&scoped=true&"
import script from "./G27Detail1TenderStat.vue?vue&type=script&lang=js&"
export * from "./G27Detail1TenderStat.vue?vue&type=script&lang=js&"
import style0 from "./G27Detail1TenderStat.vue?vue&type=style&index=0&id=8e0f3b26&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e0f3b26",
  null
  
)

export default component.exports